import {useState} from 'react';
import {Button, Checkbox, DatePicker, Form, Input} from 'antd'
import {MaskedInput} from 'antd-mask-input';

import {POLICY} from '../../../config/routes';
import {IFormRefundFree} from '../../../shared/forms/forms';
import {accountRegex, bankRegex, certRegex, passportRegex, phoneRegex} from '../../../shared/helpers/regex';

interface RefundFreeFormProps {
    onComplete: (values:IFormRefundFree) => void
}

const RefundFreeForm = ({onComplete}: RefundFreeFormProps) => {

    const [form] = Form.useForm()
    const [agree, setAgree] = useState<boolean>(true)

    const onFinish = (values:IFormRefundFree) => {
        onComplete(values)
    }

    return (
        <Form form={form} layout={'vertical'} size={'large'} validateTrigger={'onSubmit'} onFinish={onFinish}>
            <Form.Item name={'FIO'} label={'ФИО'} rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                <Input placeholder={'Иванов Иван Иванович'}/>
            </Form.Item>
            <Form.Item name={'Passport'} label={'Серия и номер паспорта'} rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {pattern: passportRegex, message: 'Не соответствует формату серии и номера паспорта'}
            ]}>
                <MaskedInput mask={'0000 000000'} placeholder={'0000 000000'} size={'large'}/>
            </Form.Item>
            <Form.Item name={'Phone'} label={'Номер телефона'} rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {pattern: phoneRegex, message: 'Не соответствует формату номера телефона'}
            ]}>
                <MaskedInput mask={'+7 (000) 000-00-00'} placeholder={'+7 (999) 888-77-66'} size={'large'}/>
            </Form.Item>
            <Form.Item name={'Email'} label={'Email'} rules={[{required: true, message: 'Заполните, пожалуйста'}, {type: 'email', message: 'Не соответствует формату email'}]}>
                <Input placeholder={'name@example.com'}/>
            </Form.Item>
            <Form.Item name={'Certificate_Number'} label={'Номер сертификата'} rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {pattern: certRegex, message: 'Не соответствует формату номера сертификата'}
            ]}>
                <Input placeholder={'23000NSB176000000'}/>
            </Form.Item>
            <Form.Item name={'Certificate_Date'} label={'Дата выдачи сертификата'} rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                <DatePicker format={'DD.MM.YYYY'}/>
            </Form.Item>
            <Form.Item name={'Bank_Name'} label={'Наименование банка'} rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                <Input placeholder={'Банк России'}/>
            </Form.Item>
            <Form.Item name={'Bank_Correspondent'} label={'Корр. счет'} rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {pattern: accountRegex, message: 'Не соответствует формату номера счета'}
            ]}>
                <Input placeholder={'30101810900000000746'}/>
            </Form.Item>
            <Form.Item name={'Bank_Number'} label={'БИК'} rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {pattern: bankRegex, message: 'Не соответствует формату БИК'}
            ]}>
                <Input placeholder={'044525600'}/>
            </Form.Item>
            <Form.Item name={'Bank_Account'} label={'Номер счета получателя'} rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {pattern: accountRegex, message: 'Не соответствует формату номера счета'}
            ]}>
                <Input placeholder={'40817810712532748696'}/>
            </Form.Item>
            <Form.Item>
                <Checkbox checked={agree} onChange={() => setAgree(!agree)}>Согласен на обработку <a href={POLICY} target={'_blank'} rel={'noreferrer'}>персональных данных и с политикой конфиденциальности</a></Checkbox>
            </Form.Item>
            <Form.Item>
                <Button type={'primary'} htmlType={'submit'} disabled={!agree}>Отправить запрос</Button>
            </Form.Item>
        </Form>
    )
}

export default RefundFreeForm