import React, {useEffect, useState} from 'react';
import _ from 'lodash';

import {Contacts} from '../index/Contacts/Contacts';

import icon_astro from './icons/icon_astro.svg'
import icon_car from './icons/icon_car.svg'
import icon_card_shield from './icons/icon_card_shield.svg'
import icon_chargeback from './icons/icon_chargeback.svg'
import icon_cleaning from './icons/icon_cleaning.svg'
import icon_docpercent from './icons/icon_docpercent.svg'
import icon_laptop from './icons/icon_laptop.svg'
import icon_libra from './icons/icon_libra.svg'
import icon_money from './icons/icon_money.svg'
import icon_moneypig from './icons/icon_moneypig.svg'
import icon_psy from './icons/icon_psy.svg'
import icon_rouble from './icons/icon_rouble.svg'
import icon_settings from './icons/icon_settings.svg'
import icon_tie from './icons/icon_tie.svg'
import {Service} from './Service';

import cls from './Services.module.scss'

interface Item {
    icon: string,
    title: string,
    text: string,
}

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}


export const Services = () => {

    const [width, setWidth] = useState(getWindowDimensions().width);
    useEffect(() => {
        function handleResize() {
            setWidth(getWindowDimensions().width);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const content:Item[] = [
        {
            icon: icon_libra,
            title: 'Юридические услуги',
            text: 'Квалифицированная юридическая помощь гражданам и юридическим лицам'
        },
        {
            icon: icon_car,
            title: 'Автоюрист',
            text: 'Квалифицированная помощь юриста при ДТП, в случае лишения прав. Взыскание выплаты по ОСАГО и/или КАСКО. Взыскание с автопроизводителя и/или автодилера'
        },
        {
            icon: icon_settings,
            title: 'Техническая помощь на дороге',
            text: 'Эвакуация автомобиля, замена колес, ремонт проколов, прогрев и запуск двигателя и др'
        },
        {
            icon: icon_rouble,
            title: 'Финансовые консультации',
            text: 'Вопросы по криптовалюте, торговле на финансовых рынках, по исламскому банкингу'
        },
        {
            icon: icon_docpercent,
            title: 'Налоговый вычет',
            text: 'Консультации по оформлению документов для получения налогового вычета'
        },
        {
            icon: icon_tie,
            title: 'Карьерные консультации',
            text: 'Онлайн-консультация с экспертом о развитии карьеры и поиске работы'
        },
        {
            icon: icon_moneypig,
            title: 'Банкротство',
            text: 'Квалифицированная юридическая помощь гражданам по вопросам банкротства физических лиц'
        },
        {
            icon: icon_astro,
            title: 'Астролог онлайн',
            text: 'Профессиональные астрологи готовы ответить на любой волнующий вопрос'
        },
        {
            icon: icon_psy,
            title: 'Психолог онлайн',
            text: 'Онлайн консультации у ведущих психологов для взрослых и детей'
        },
        {
            icon: icon_laptop,
            title: 'Компьютерная помощь',
            text: 'Установка, настройка и обновление ПО; Подбор сервиса для ремонта оборудования; Антивирусная защита и профилактика и др'
        },
        {
            icon: icon_money,
            title: 'Деньги онлайн',
            text: 'подбор банка/МФО/ страховой компании. Помощь в исправление кредитной истории'
        },
        {
            icon: icon_cleaning,
            title: 'Уборка помещений',
            text: 'Сухая и влажная уборка; Мытьё окон и зеркал; Химчистка мебели; Мытьё вытяжки, холодильника и др.'
        },
        {
            icon: icon_card_shield,
            title: 'Финансовая защита',
            text: 'Погашение кредита в случае непредвиденных обстоятельств'
        },
        {
            icon: icon_chargeback,
            title: 'Chargeback',
            text: 'Автоматизированный сервис опротестования банковской транзакции с целью возврата денег владельцу'
        },
    ]

    const columns = width > 960 ? 4 : width >= 768 ? 3 : width > 560 ? 2 : 1
    const gutter = width > 930 ? 40 : 20

    const style = {
        '--columns': columns,
        '--gutter': `${gutter}px`,
    } as React.CSSProperties;

    let columnsContent:[Item[]] = [[]]
    for (let i = 1; i < columns; i++) {
        columnsContent.push([])
    }

    let a = 0
    for (let i = 0; i < content.length; i++) {
        if (i%columns===0) {a = 0}
        console.log(`a = ${a}, i = ${i}`)
        columnsContent[a].push(content[i])
        a++
    }

    console.log('columnsContent', columnsContent)

    return (
        <div>
            <div className={'content'}>
                {/*<Banners/>*/}
                <div className={cls.page}>
                    <h2>Сервисы</h2>
                    <div className={cls.services} style={style}>
                        {_.map(columnsContent, (column, index) => (
                            <div key={index} className={cls.column}>
                                {_.map(column, (item, index) => (
                                    <Service key={index} icon={item.icon} title={item.title} text={item.text}/>
                                ))}
                            </div>
                        ))}

                    </div>
                </div>
                <Contacts/>
            </div>
        </div>
    )
}



