/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ENUM_CONSULTATION_STATUS {
  DONE = "DONE",
  ERROR = "ERROR",
  IN_PROGRESS = "IN_PROGRESS",
  NEW = "NEW",
}

export enum ENUM_PARTNER_STATUS {
  DONE = "DONE",
  ERROR = "ERROR",
  IN_PROGRESS = "IN_PROGRESS",
  NEW = "NEW",
}

export enum ENUM_REFUNDREQUEST_ORDERSTATUS {
  DONE = "DONE",
  ERROR = "ERROR",
  NEW = "NEW",
  WAITING = "WAITING",
}

export enum ENUM_REFUNDREQUEST_SENDER {
  help_insurfinanceconsulting_ru = "help_insurfinanceconsulting_ru",
  help_skct_ru = "help_skct_ru",
  system = "system",
}

export enum ENUM_REFUNDREQUEST_STATUS {
  GEN_ERROR_7 = "GEN_ERROR_7",
  IN_GEN_QUEUE_1 = "IN_GEN_QUEUE_1",
  READY_TO_SEND_2 = "READY_TO_SEND_2",
  RECEIVE_SIGN_4 = "RECEIVE_SIGN_4",
  REFUND_DONE_5 = "REFUND_DONE_5",
  REJECT_6 = "REJECT_6",
  SENDED_TO_CLIENT_3 = "SENDED_TO_CLIENT_3",
}

export enum ENUM_REFUNDREQUEST_TEMPLATE {
  CERT_NUMBER_ERROR = "CERT_NUMBER_ERROR",
  DEFAULT_REQUEST = "DEFAULT_REQUEST",
  NON_RETURN = "NON_RETURN",
  PURCHASE_PLACE = "PURCHASE_PLACE",
  SEVEN_DAYS = "SEVEN_DAYS",
  WALK_TO_MAIL = "WALK_TO_MAIL",
  WALK_TO_OFFICE = "WALK_TO_OFFICE",
}

export enum ENUM_REFUNDREQUEST_TYPE {
  free = "free",
  vip = "vip",
}

export interface ConsultationInput {
  Certificate_Number?: string | null;
  Certificate_Date?: any | null;
  FIO?: string | null;
  Phone?: string | null;
  status?: ENUM_CONSULTATION_STATUS | null;
}

export interface LossInput {
  FIO?: string | null;
  Phone?: string | null;
  Certificate_Number?: string | null;
  Certificate_Date?: any | null;
  Comment?: string | null;
  Email?: string | null;
}

export interface PartnerInput {
  FIO?: string | null;
  Company?: string | null;
  Phone?: string | null;
  status?: ENUM_PARTNER_STATUS | null;
}

export interface RefundRequestInput {
  status?: ENUM_REFUNDREQUEST_STATUS | null;
  requestPdfFileUrl?: string | null;
  requestData?: any | null;
  validationErrorMessage?: string | null;
  sender?: ENUM_REFUNDREQUEST_SENDER | null;
  template?: ENUM_REFUNDREQUEST_TEMPLATE | null;
  type?: ENUM_REFUNDREQUEST_TYPE | null;
  orderNumber?: string | null;
  orderStatus?: ENUM_REFUNDREQUEST_ORDERSTATUS | null;
  orderLink?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
