import cn from 'classnames';

import cls from './Instruction.module.scss'

interface IBlock {
    outlined?: boolean,
    title: string,
    text: string,
}

export const Block = ({outlined, title, text}: IBlock) => (
    <div className={cn([cls.block, outlined && cls.outlined])}>
        <h3>{title}</h3>
        <div className={cls.text}>{text}</div>
    </div>
)