import FormLayout from '../../../components/FormLayout/FormLayout';

import parter_zaim from './parter_zaim.jpg';
import {Partner} from './Partner';
import partner_aliance from './partner_aliance.jpg';
import partner_finconnect from './partner_finconnect.jpg';
import partner_sms from './partner_sms.jpg';
import partner_vivus from './partner_vivus.jpg';

import cls from './Partners.module.scss'

export const Partners = () => (
    <FormLayout>
        <h2>Наши партнёры</h2>
        <div className={cls.partners}>
            <Partner img={parter_zaim}/>
            <Partner img={partner_sms}/>
            <Partner img={partner_aliance}/>
            <Partner img={partner_vivus}/>
            <Partner img={partner_finconnect}/>
        </div>
    </FormLayout>
)