import {useState} from 'react';
import {DownOutlined, UpOutlined} from '@ant-design/icons';
import cn from 'classnames';

import cls from './Services.module.scss'

interface ICard {
    icon: string,
    title: string,
    text: string,
}

export const Service = ({icon, title, text}: ICard) => {

    const [open, setOpen] = useState<boolean>(false)

    return (
        <div className={cls.card}>
            <div className={cn([cls.wrapper, open && cls.opened])}>
                <div className={cls.content}>
                    <img className={cls.icon} src={icon} alt={'Service Icon'}/>
                    <h3>{title}</h3>
                    <div onClick={() => setOpen(!open)} className={cls.open}>{open ? 'Скрыть' : 'Подробнее'} {open ?
                        <UpOutlined/> : <DownOutlined/>}</div>
                </div>
                {open && <div className={cls.text}>{text}</div>}
            </div>
        </div>
    )
}