
import cls from './Contacts.module.scss'
export const Contacts = () => (
    <div className={cls.contacts}>
        <h2>Контакты</h2>
        <div className={cls.mail}>
            <div className={cls.container}>
                <div>Почта для обращений</div>
                <div className={cls.title}><a href={'mailto:help@insurfinanceconsulting.ru'}>help@insurfinanceconsulting.ru</a></div>
            </div>
        </div>
        <div className={cls.legal}>
            <div className={cls.wrapper}>
                <div className={cls.container}>
                    <div className={cls.title}>ООО «СК Консалтинг»</div>
                    <div>ИНН 9723122494</div>
                    <div>ОГРН 1217700392942</div>
                    <div>КПП 772301001</div>
                    <div>АО «АЛЬФА-БАНК»</div>
                    <div>р/с 407702810001770000693</div>
                    <div>Юридический адрес: 109451, город Москва, Мячковский б-р, д. 5, к.1, этаж/помещ. 1/I ком.5</div>
                    <div>Адрес для корреспонденции: 143003, Московская обл., г. Одинцово, ул. Маршала Жукова, д. 32, а/я 15</div>
                </div>
            </div>
            <div className={cls.wrapper}>
                <div className={cls.container}>
                    <div className={cls.title}>ООО «ИТ Прогресс»</div>
                    <div>ИНН 9723203136</div>
                    <div>ОГРН 1237700445520</div>
                    <div>КПП 772301001</div>
                    <div>АО «АЛЬФА-БАНК»</div>
                    <div>р/с 407702810902370018472</div>
                    <div>Юридический адрес: 109382, Москва, УЛ АРМАВИРСКАЯ, Д. 6, ПОМЕЩ. 1/П</div>
                    <div>Адрес для корреспонденции: 143 003, Московская обл., г. Одинцово, ул. Маршала Жукова, д. 32, а/я 16</div>
                </div>
            </div>
            {/*<div className={cls.wrapper}>*/}
            {/*    <div className={cls.container}>*/}
            {/*        <div className={cls.title}>ООО «ИТ Консул»</div>*/}
            {/*        <div>ИНН 9724139250</div>*/}
            {/*        <div>ОГРН 1237700492204</div>*/}
            {/*        <div>КПП 772401001</div>*/}
            {/*        <div>АО «АЛЬФА-БАНК»</div>*/}
            {/*        <div>р/с 407702810802640004339</div>*/}
            {/*        <div>Юридический адрес: 115230, г. Москва, проезд Хлебозаводский, д. 7, стр. 9, помещение 24/Н</div>*/}
            {/*        <div>Адрес для корреспонденции: 143 003, Московская обл., г. Одинцово, ул. Маршала Жукова, д. 32, а/я 16</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    </div>
)