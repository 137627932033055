import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import {Button} from 'antd';

import {
    ENUM_REFUNDREQUEST_SENDER,
    ENUM_REFUNDREQUEST_TYPE,
    RefundRequestInput
} from '../../__generated__/global-types';
import {
    REFUND_FREE, REFUND_SUCCESS
} from '../../config/routes';
import {IFormRefund} from '../../shared/forms/forms';
import {createRefundRequest, createRefundRequestVariables} from '../../shared/graphql/__generated__/createRefundRequest';
import {CREATE_REFUND_REQUEST} from '../../shared/graphql/createRefundRequest';
import {Contacts} from '../index/Contacts/Contacts';

import RefundForm from './RefundForm';

import cls from './Refund.module.scss';

const Refund: FC = () => {

    const navigate = useNavigate();
    const [createRefundRequest] = useMutation<createRefundRequest,createRefundRequestVariables>(CREATE_REFUND_REQUEST)



    const onComplete = async (values:IFormRefund) => {

        const data:RefundRequestInput = {
            requestData: values,
            type: ENUM_REFUNDREQUEST_TYPE.vip,
            sender: ENUM_REFUNDREQUEST_SENDER.system,
        }

        await createRefundRequest({
            variables: {
                data: data,
            },
            onCompleted: () => {
                navigate(REFUND_SUCCESS)
            }
        })
    }

    return (
        <div>
            <div className={'content'}>
                {/*<Banners/>*/}
                <div className={cls.forms}>
                    <div className={cls.form}>
                        <h2>Расторгнуть договор<br/>«Под ключ»</h2>
                        <p className={cls.subhead}>Услуга платная</p>
                        <p>
                            Ваш персональный менеджер подготовит все необходимы документы для возврата денежных средств за Сертификат купленного не позднее 14 календарных дней.
                        </p>
                        <RefundForm onComplete={onComplete}/>
                        <p>
                            В стоимость услуги входит
                        </p>
                        <ul>
                            <li>
                                Персональный менеджер
                            </li>
                            <li>
                                Заполнение/ составление всех необходимых документов
                            </li>
                            <li>
                                Приоритетный возврат в течение 3-х рабочих дней, если не прошло более 14 календарных дней с момента покупки Сертификата
                            </li>
                        </ul>

                    </div>
                    <div className={cls.form}>
                        <h2>Расторгнуть договор</h2>
                        <p className={cls.subheadFree}>Услуга бесплатная</p>

                        <p>
                            Заполнение формы заявление на возврат для получения денежных средств за купленный Сертификат в период охлаждения - 14 календарных дней с момента покупки Сертификата
                        </p>
                        <p>
                            Услуга бесплатная
                        </p>
                        <Button size={'large'} onClick={() => navigate(REFUND_FREE)}>Заполнить заявление</Button>
                    </div>
                </div>
                <Contacts/>
            </div>
        </div>
    );
}

export default Refund;



