
import {Card} from './Card';
import img_calc from './img_calc.jpg'
import img_law from './img_law.jpg'
import img_med from './img_med.jpg'

import cls from './Cards.module.scss'
export const Cards = () => (
    <div className={cls.cards}>
        <Card img={img_law} text={'Квалифицированная юридическая помощь гражданам и юридическим лицам'} title={'Телеюрист'}/>
        <Card img={img_med} text={'Подбор медицинского учреждения, подбор лабораторий, консультации по получению налогового вычета'} title={'Медицинская помощь онлайн'}/>
        <Card img={img_calc} text={'Погашение остатка ссудной задолженности, в случае непредвиденных обстоятельств'} title={'Финансовая защита'}/>
    </div>
)