import {useState} from 'react';
import {DownOutlined, UpOutlined} from '@ant-design/icons';
import cn from 'classnames';

import cls from './Cards.module.scss'

interface ICard {
    img: string,
    title: string,
    text: string,
}

export const Card = ({img, title, text}: ICard) => {

    const [open, setOpen] = useState<boolean>(false)

    return (
        <div className={cls.card}>
            <div className={cn([cls.wrapper, open && cls.opened])}>
                <div className={cls.content}>
                    <div className={cls.cover} style={{backgroundImage: `url(${img})`}}></div>
                    <h2>{title}</h2>
                    <div onClick={() => setOpen(!open)} className={cls.open}>{open ? 'Скрыть' : 'Подробнее'} {open ?
                        <UpOutlined/> : <DownOutlined/>}</div>
                </div>
                {open && <div className={cls.text}>{text}</div>}
            </div>
        </div>
    )
}