import {useState} from 'react';
import {CameraOutlined} from '@ant-design/icons';
import {useMutation} from '@apollo/client';
import {Button, Checkbox, Form, Input, message,Popover, Upload} from 'antd'
import type {RcFile} from 'antd/es/upload/interface';
import {MaskedInput} from 'antd-mask-input';

import Spinner from '../../components/common/Spinner';
import {OFFER, POLICY} from '../../config/routes';
import {getApiBase} from '../../env';
import {IFormRefund} from '../../shared/forms/forms';
import {upload, uploadVariables} from '../../shared/graphql/__generated__/upload';
import {UPLOAD} from '../../shared/graphql/upload';
import {phoneRegex} from '../../shared/helpers/regex';

import cls from './Refund.module.scss'

interface FormProps {
    onComplete: (values: IFormRefund) => void
}

const {Dragger} = Upload;

const RefundForm = ({onComplete}: FormProps) => {

    const [form] = Form.useForm()
    const [agree, setAgree] = useState<boolean>(true)

    const [upload, uploadData] = useMutation<upload, uploadVariables>(UPLOAD);
    const [photoUrl, setPhotoUrl] = useState<string>()

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Только файлы в формате JPG и PNG');
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error('Изображение должно быть меньше 10Мб');
        }
        return isJpgOrPng && isLt10M;
    };

    const onFinish = (values: IFormRefund) => {
        onComplete(values)
    }

    return (
        <Form form={form} layout={'vertical'} size={'large'} validateTrigger={'onSubmit'} onFinish={onFinish}>
            <Form.Item name={'FIO'} label={'ФИО'} rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                <Input placeholder={'Иванов Иван Иванович'}/>
            </Form.Item>
            <Form.Item name={'Phone'} label={'Номер телефона'} rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {pattern: phoneRegex, message: 'Не соответствует формату номера телефона'}
            ]}>
                <MaskedInput mask={'+7 (000) 000-00-00'} placeholder={'+7 (999) 888-77-66'} size={'large'}/>
            </Form.Item>
            <Form.Item name={'Email'} label={'Email'} rules={[{required: true, message: 'Заполните, пожалуйста'}, {
                type: 'email',
                message: 'Не соответствует формату email'
            }]}>
                <Input placeholder={'name@example.com'}/>
            </Form.Item>
            <Form.Item name={'Photo'} hidden={true} rules={[
                {required: true, message: 'Заполните, пожалуйста'},
            ]}>
                <Input/>
            </Form.Item>

            <div className={cls.label}><span style={{color: '#ff4d4f'}}>*</span> Банковские реквизиты</div>
            <Dragger
                multiple={false}
                showUploadList={false}
                beforeUpload={beforeUpload}
                customRequest={async ({file}) => {
                    await upload({
                        variables: {file: file},
                        onCompleted: (data) => {
                            const photo = `${getApiBase()}${data.upload.data?.attributes?.url}`
                            setPhotoUrl(photo)
                            form.setFieldValue('Photo', photo)
                        },
                    });
                }}
            >
                <p className="ant-upload-drag-icon">
                    <CameraOutlined/>
                </p>
                <p className="ant-upload-text">Нажмите для загрузки или бросьте сюда файл </p>
                <p className="ant-upload-hint">
                    Изображение должно содержать банковские реквизиты
                </p>
            </Dragger>

            {uploadData.loading && <Spinner/>}
            {photoUrl && <div className={cls.preview} style={{backgroundImage: `url(${photoUrl})`}}/>}
            {!photoUrl && <div className={cls.error}><span style={{color: '#ff4d4f'}}>Загрузите файл</span></div>}

            <Form.Item>
                <Checkbox checked={agree} onChange={() => setAgree(!agree)}>Согласен с <a href={OFFER} target={'_blank'} rel={'noreferrer'}>офертой</a>, на обработку <a href={POLICY} target={'_blank'} rel={'noreferrer'}>персональных
                    данных и с политикой конфиденциальности</a></Checkbox>
            </Form.Item>
            <Form.Item>
                <div className={cls.line}>
                    <Popover title={'Функция временно не доступна'}>
                        <Button type={'primary'} htmlType={'submit'} disabled={true}>Перейти к оплате</Button>
                    </Popover>
                    <div className={cls.price}>Стоимость — 399&nbsp;₽</div>
                </div>
            </Form.Item>
        </Form>
    )
}

export default RefundForm