import React, {FC} from 'react';

import {Cards} from './Cards/Cards';
import {Contacts} from './Contacts/Contacts';
import {Instruction} from './Instruction/Instruction';
import {Partners} from './Partners/Partners';
import {Reviews} from './Reviews/Reviews';


const Index: FC = () => (
    <>
        <div className={'content'}>
            {/*<Banners/>*/}
            <Cards/>
            <Instruction/>
            <Reviews/>
        </div>
        <Partners/>
        <div className={'content'}>
            <Contacts/>
        </div>
    </>
    );

export default Index;



