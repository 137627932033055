import React, {FC} from 'react';
import {provider, useInstance} from 'react-ioc';
import {BrowserRouter as Router, Navigate, Route, Routes,} from 'react-router-dom';
import {ApolloClient, ApolloProvider} from '@apollo/client';
import {ConfigProvider} from 'antd';
import ru_RU from 'antd/locale/ru_RU';
import {observer} from 'mobx-react-lite';

import 'dayjs/locale/ru';

import ScrollToTop from '../components/common/ScrollToTop';
import Layout from '../components/layout/Layout';
import {
    ABOUT,
    CONSULTATION, CONSULTATION_SUCCESS, DOCUMENTS,
    FAQ,
    INDEX, LOGIN, LOSS, LOSS_SUCCESS,
    NOT_FOUND,
    PARTNER, PARTNER_SUCCESS,
    REFUND,
    REFUND_FREE,
    REFUND_SUCCESS,
    ROOT,
    SERVICES,
    SUCCESS_DEFAULT_REQUEST, SUCCESS_NON_RETURN,
    SUCCESS_PURCHASE_PLACE, SUCCESS_SEVEN_DAYS, SUCCESS_WALK_TO_MAIL, SUCCESS_WALK_TO_OFFICE,
} from '../config/routes';
import ApolloClientDI from '../model/graphql/ApolloClientDI';
import StoreDI from '../model/store/StoreDI';
import About from '../pages/About/About';
import Consultation from '../pages/Consultation/Consultation';
import Document from '../pages/Document/Document';
import Faq from '../pages/Faq/Faq';
import Index from '../pages/index/Index';
import Login from '../pages/Login/Login';
import Loss from '../pages/Loss/Loss';
import NotFound from '../pages/NotFound';
import Partner from '../pages/Partner/Partner';
import RefundFree from '../pages/Refund/Free/RefundFree';
import { SuccessDefaultRequest} from '../pages/Refund/Free/Success/SuccessDefaultRequest';
import {SuccessNonReturn} from '../pages/Refund/Free/Success/SuccessNonReturn';
import {SuccessPurchasePlace} from '../pages/Refund/Free/Success/SuccessPurchasePlace';
import {SuccessSevenDays} from '../pages/Refund/Free/Success/SuccessSevenDays';
import {SuccessWalkToMail} from '../pages/Refund/Free/Success/SuccessWalkToMail';
import {SuccessWalkToOffice} from '../pages/Refund/Free/Success/SuccessWalkToOffice';
import Refund from '../pages/Refund/Refund';
import {Services} from '../pages/Services/Services';
import {RefundSuccess} from '../pages/Success/RefundSuccess';
import {Success} from '../pages/Success/Success';

import './App.scss';

const App: FC = () => {

    const apolloClient = useInstance(ApolloClient);

    return (
        <ConfigProvider locale={ru_RU} theme={{
            token: {
                colorPrimary: '#FF5C00',
            },
        }}>
            <ApolloProvider client={apolloClient}>
                <Router>
                    <ScrollToTop/>

                    <Routes>

                        <Route path={ROOT} element={<Layout/>}>
                            <Route path={INDEX} element={<Index/>}/>
                            <Route path={ABOUT} element={<About/>}/>
                            <Route path={PARTNER} element={<Partner/>}/>
                            <Route path={PARTNER_SUCCESS} element={<Success/>}/>
                            <Route path={LOSS} element={<Loss/>}/>
                            <Route path={LOSS_SUCCESS} element={<Success/>}/>
                            <Route path={FAQ} element={<Faq/>}/>
                            <Route path={REFUND} element={<Refund/>}/>
                            <Route path={REFUND_SUCCESS} element={<RefundSuccess/>}/>
                            <Route path={REFUND_FREE} element={<RefundFree/>}/>
                            <Route path={SUCCESS_DEFAULT_REQUEST} element={<SuccessDefaultRequest/>}/>
                            <Route path={SUCCESS_SEVEN_DAYS} element={<SuccessSevenDays/>}/>
                            <Route path={SUCCESS_WALK_TO_OFFICE} element={<SuccessWalkToOffice/>}/>
                            <Route path={SUCCESS_WALK_TO_MAIL} element={<SuccessWalkToMail/>}/>
                            <Route path={SUCCESS_NON_RETURN} element={<SuccessNonReturn/>}/>
                            <Route path={SUCCESS_PURCHASE_PLACE} element={<SuccessPurchasePlace/>}/>
                            <Route path={SERVICES} element={<Services/>}/>
                            <Route path={CONSULTATION} element={<Consultation/>}/>
                            <Route path={CONSULTATION_SUCCESS} element={<Success/>}/>
                            <Route path={DOCUMENTS} element={<Document/>}/>
                            <Route path={LOGIN} element={<Login/>}/>
                            <Route path="*" element={<Navigate to={INDEX}/>}/>
                            <Route path={NOT_FOUND} element={<NotFound/>}/>
                        </Route>
                    </Routes>
                </Router>
            </ApolloProvider>
        </ConfigProvider>
);
};

export default provider(
StoreDI(),
ApolloClientDI(),
)(observer(App));
