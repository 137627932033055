import React from 'react';
import {Link} from 'react-router-dom';
import _ from 'lodash';

import {DOCUMENTS, INDEX, LOGIN} from '../../config/routes';
import {menuItems} from '../Header/Header';

import logo from './SKK-logo-white.svg';

import cls from './Footer.module.scss'

export const policyItems = [
    {
        link: DOCUMENTS,
        label: 'Оферты и документы',
    },
    {
        link: LOGIN,
        label: 'Личный кабинет',
    },
]

export const legalItems = [
    {
        name: 'ООО «СК Консалтинг»',
        INN: '9723122494',
        OGRN: '1217700392942',
    },
    {
        name: 'ООО «ИТ Прогресс»',
        INN: '9723203136',
        OGRN: '1237700445520',
    },
    // {
    //     name: 'ООО «ИТ Консул»',
    //     INN: '9724139250',
    //     OGRN: '1237700492204',
    // },
]

const Footer = () => (
        <div className={cls.footer}>
            <div className={cls.content}>
                <div className={cls.logo}>
                    <Link to={INDEX}>
                        <img src={logo} alt={'logo'}/>
                    </Link>
                </div>
                <div className={cls.wrap}>
                    <div className={cls.caption}>
                        <p>© СКК 2021–2023</p>
                        <p>Все права защищены</p>
                    </div>
                    <div className={cls.menu}>
                        {_.map(menuItems, item => (
                            <p key={item.link}><Link to={item.link}>{item.label}</Link></p>
                        ))}
                    </div>
                    <div className={cls.policy}>
                        {_.map(policyItems, item => (
                            <p key={item.link}><Link to={item.link}>{item.label}</Link></p>
                        ))}
                    </div>
                        {_.map(legalItems, item => (
                            <div key={item.INN} className={cls.legal}>
                                <p>{item.name}</p>
                                <p>ИНН {item.INN}</p>
                                <p>ОГРН {item.OGRN}</p>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )

export default Footer