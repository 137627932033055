import React from 'react';
import {useNavigate} from 'react-router-dom';
import { Button } from 'antd';

import {INDEX} from '../../../../config/routes';

import cls from './Success.module.scss'


export const SuccessSevenDays = () => {

    const navigate = useNavigate()

    return (
    <div className={cls.page}>
        <div className={'content'}>
            <div className={cls.text}>
                <h2>Инструкция по возврату</h2>
                <p>
                    Уважаемый Клиент,<br/>
                    Группа компаний «СК Консалтинг» благодарит Вас за обращение.
                </p>
                <p>
                    Ваша заявка на возврат принята в работу.
                    Денежные средства вернутся на карту, с которой были списаны при оформлении Сертификата в течение 7 рабочих дней, в случае если это предусмотрено условиями сертификата.
                </p>
                <Button type={'primary'} size={'large'} onClick={() => navigate(INDEX)}>Хорошо</Button>
            </div>
        </div>

    </div>
)}


