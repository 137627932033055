// INDEX
export const ROOT = '';
export const INDEX = '/';
export const ABOUT = '/about';
export const PARTNER = '/partner';
export const PARTNER_SUCCESS = '/partner/success';
export const LOSS = '/loss';
export const LOSS_SUCCESS = '/loss/success';
export const SERVICES = '/services';
export const FAQ = '/faq';
export const CONSULTATION = '/consultation';
export const CONSULTATION_SUCCESS = '/consultation/success';
export const REFUND = '/refund';
export const REFUND_FREE= '/refund/free';
export const SUCCESS_DEFAULT_REQUEST= '/refund/free/default';
export const toSuccessDefaultRequest = (url: string) => `${SUCCESS_DEFAULT_REQUEST}?url=${url}`;
export const SUCCESS_SEVEN_DAYS= '/refund/free/seven';
export const SUCCESS_WALK_TO_OFFICE= '/refund/free/office';
export const SUCCESS_WALK_TO_MAIL= '/refund/free/mail';
export const toSuccessWalkToMail = (url: string) => `${SUCCESS_WALK_TO_MAIL}?url=${url}`;
export const SUCCESS_NON_RETURN= '/refund/free/noreturn';
export const SUCCESS_PURCHASE_PLACE= '/refund/free/purchase';
export const REFUND_SUCCESS = '/refund/success';
export const LOGIN = '/login';
export const DOCUMENTS = '/doc';
export const POLICY = '/docs/policy.pdf';
export const OFFER = '/docs/offer.pdf';
export const OFFER_MED = '/docs/offer_med.pdf';
export const OFFER_LAW = '/docs/offer_law.pdf';
export const OFFER_FIN = '/docs/offer_fin.pdf';
export const NOT_FOUND = '/404';

